<template>
    <DefaultVideoPlayer
        v-if="type === 'default'"
        :preview-image-link="previewImageLink"
        :link="link"
        :progress="progress ?? 0"
        :is-muted="muted"
        :is-controls="controls"
        @pause="pause" />

    <PreviewVideoPlayer v-else-if="type === 'preview'" :preview-image-link="previewImageLink" :link="link" />
</template>

<script setup>
// @see https://github.com/LeonidShv/vue-hls-video-player
import DefaultVideoPlayer from './Partials/VideoPlayer/DefaultVideoPlayer.vue';
import PreviewVideoPlayer from './Partials/VideoPlayer/PreviewVideoPlayer.vue';
import defaultPreviewImage from '@/images/components/video_preview_placeholder.gif';

const emit = defineEmits(['pause']);

defineProps({
    previewImageLink: {
        type: String,
        default: defaultPreviewImage,
    },
    link: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: 'preview',
    },
    progress: {
        type: Number,
        default: 0,
    },
    muted: {
        type: Boolean,
        default: true,
    },
    controls: {
        type: Boolean,
        default: false,
    },
});

function pause(currentTime) {
    emit('pause', currentTime);
}
</script>
