<template>
    <div class="relative w-full flex justify-center items-center" @mouseover="hover" @mouseleave="leave">
        <video
            ref="video"
            class="w-full my-0"
            :poster="previewImageLink"
            :controls="isControls"
            :title="title"
            :loop="loop"
            @pause="pause"
            @ended="pause"
            @keyup="changeSpeed">
            <source :src="link" type="application/x-mpegURL" />
        </video>
        <div
            class="absolute inset-0 flex justify-end items-end z-10 opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            <div class="w-10 h-10 bg-black" @click="toggleAudio(!video.mute)">
                <svg
                    x="0px"
                    y="0px"
                    viewBox="0 0 40 34"
                    enable-background="new 0 0 40 34"
                    aria-hidden="true"
                    style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%">
                    <g style="transform: translateX(1.25px); transition: transform 100ms ease 0s">
                        <g>
                            <path
                                d="M13.8,14.2c-0.5,0.5-1.4,0.8-2,0.8h-1.6C9.5,15,9,15.5,9,16.2v1.6c0,0.7,0.5,1.2,1.2,1.2h1.6c0.7,0,1.6,0.4,2,0.8l2.3,2.3c0.5,0.5,0.8,0.3,0.8-0.4v-9.6c0-0.7-0.4-0.8-0.8-0.4L13.8,14.2z"></path>
                        </g>
                        <g>
                            <path
                                fill="none"
                                stroke="#ffffff"
                                stroke-line-cap="round"
                                stroke-miterlimit="10"
                                stroke-width="2"
                                d="M22,11.7c0,0,1.1,2.5,1.1,5s-1.1,5-1.1,5"
                                style="opacity: 1; transition: opacity 100ms ease 0s"></path>
                            <path
                                fill="none"
                                stroke="#ffffff"
                                stroke-line-cap="round"
                                stroke-miterlimit="10"
                                stroke-width="2"
                                d="M25.8,9.2c0,0,1.7,3.8,1.7,7.5c0,3.7-1.7,7.5-1.7,7.5"
                                style="opacity: 1; transition: opacity 100ms ease 0s"></path>
                        </g>
                        <g style="opacity: 0; transition: opacity 100ms ease 0s">
                            <line
                                fill="none"
                                stroke="#ffffff"
                                stroke-line-cap="round"
                                stroke-miterlimit="10"
                                stroke-width="1.8102"
                                x1="19.2"
                                y1="15"
                                x2="23.2"
                                y2="19"></line>
                            <line
                                fill="none"
                                stroke="#ffffff"
                                stroke-line-cap="round"
                                stroke-miterlimit="10"
                                stroke-width="1.8102"
                                x1="19.2"
                                y1="19"
                                x2="23.2"
                                y2="15"></line>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, onUpdated, ref} from 'vue';
import Hls from 'hls.js';

const props = defineProps({
    autoplay: {
        type: Boolean,
        default: true,
    },
    enableHover: {
        type: Boolean,
        default: false,
    },
    previewImageLink: {
        type: String,
        default: '',
    },
    link: {
        type: String,
        default: '',
    },
    progress: {
        type: Number,
        default: 0,
    },
    title: {
        type: String,
        default: '',
    },
    isMuted: {
        type: Boolean,
        default: false,
    },
    isControls: {
        type: Boolean,
        default: true,
    },
    loop: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(['pause', 'test', 'hover', 'leave']);
const video = ref(null);

onMounted(() => {
    prepareVideoPlayer();
});

onUpdated(() => {
    prepareVideoPlayer();
});

const isPlaying = () =>
    video.value.currentTime > 0 &&
    !video.value.paused &&
    !video.value.ended &&
    video.value.readyState > video.value.HAVE_CURRENT_DATA;

function prepareVideoPlayer() {
    let hls = new Hls();
    let stream = props.link;
    hls.loadSource(stream);
    if (video.value) {
        hls.attachMedia(video.value);
        if (props.isMuted) {
            toggleAudio(true);
        }
        video.value.currentTime = props.progress;
    }
    if (props.autoplay && !isPlaying()) {
        video.value.play();
    }
}

function pause() {
    const currentTime = video?.value?.currentTime || 0;

    emit('pause', currentTime);
}

function hover() {
    if (props.enableHover && !isPlaying()) {
        // console.log('play');
        video.value.play();
    }
    emit('hover');
}

function leave() {
    // console.log('leave');
    emit('leave');
    if (props.enableHover && isPlaying()) {
        // console.log('pause');
        video.value.pause();
    }
}

function toggleAudio(muted) {
    video.value.mute = muted;
    video.value.volume = muted ? 0 : 1;
}

function changeSpeed(e) {
    if (e.key === 'w' && video.value) {
        video.value.playbackRate = video.value.playbackRate + 0.25;
    } else if (e.key === 's' && video.value) {
        video.value.playbackRate = video.value.playbackRate - 0.25;
    }
}
</script>
